import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const ssr = process.env.VUE_APP_SSR === 'true'
const plugins = ssr
    ? []
    : [createPersistedState({
        paths: ['auth']
    })]

export const state = () => ({
    brand: 'Luma',
    sidebarOpen: false,
})

export const getters = {
    settings: state => state.settings.state
}

import api from './api'
import settings from './settings'
import settingsMentoring from './settingsMentoring'
import layoutConfig from './layoutConfig'
import sidebarConfig from './sidebarConfig'
import sidebarMenuConfig from './sidebarMenuConfig'
import navbarConfig from './navbarConfig'
import auth from './auth'

export const createStore = () => {
    return new Vuex.Store({
        state,
        getters,
        mutations: {
        setSidebarOpen(state, isOpen) {
            state.sidebarOpen = isOpen;
          },        
        },
        actions: {
            toggleSidebar({ commit, state }) {
                commit('setSidebarOpen', !state.sidebarOpen);
              },        
        },
        modules: {
            api,
            settings,
            layoutConfig,
            sidebarConfig,
            sidebarMenuConfig,
            navbarConfig,
            auth,
            settingsMentoring
        },
        plugins
    })
}

const store = createStore()
export default store
