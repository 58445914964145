// teste subir em prod2
import Vue from 'vue'
import App from './App.vue'

import {createRouter} from './router'
import store from './store'

import i18n from './i18n'
import http from './http/index'

import './plugins/vue-meta'
import './plugins/bootstrap-vue'
import './plugins/vue-quill-editor'
import './plugins/fmv-vendor'
import './plugins/fmv-layout'
import './plugins/fmv-charts'
import './plugins/fmv-highlight'
import './plugins/app'

// Plugins de Notificação
import NotifyError from './plugins/notify-error'
import NotifySuccess from './plugins/notify-success'

import '@/assets/scss/vendor/material-icons.scss'
import '@/assets/scss/vendor/fontawesome.scss'
import '@/assets/scss/app.scss'
import '@/assets/scss/vendor/quill.scss'

import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false

// Importações adicionais...
import VueTour from 'vue-tour';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';

require('vue-tour/dist/vue-tour.css')
import Maska from 'maska';
import Notifications from 'vue-notification'

// configs de chat
import env from "@/env";
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: env.pusher_key,
    cluster: env.pusher_cluster,
    wsHost:env.websocket_server,
    wsPort:env.websocket_port,
    forceTLS: env.websocket_ssl,
    disableStats: true,
    encrypted: env.websocket_ssl
})


// Registrando os plugins
Vue.use(VueTour)
    .use(BootstrapVue)
    .use(NotifyError)
    .use(NotifySuccess)
    .use(Maska)
    .use(Notifications)
    .component('apexchart', VueApexCharts)
    .use(VueApexCharts)


export const createApp = async ({
                                    beforeApp = () => {
                                    },
                                    afterApp = () => {
                                    }
                                } = {}) => {
    const router = createRouter()

    await beforeApp({
        router,
        store
    })

    const app = new Vue({
        router,
        store,
        i18n,
        http,
        render: h => h(App),
        mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
    })

    const result = {
        app,
        router,
        store
    }

    await afterApp(result)

    return result
}
