import axios from "axios";
import env from "@/env";

const http = axios.create({
    baseURL: env.api,
});

// Função que retorna o token atualizado
function getToken() {
    return localStorage.getItem('token');
}

// Adicionando um interceptador de requisição ao Axios
http.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default http;
