import http from "@/http/index";
import env from '@/env'
import router from '@/router/index'

export const state = () => ({
    token: null,
    user: null
})

export const getters = {
    isLoggedIn: state => !!state.user,
}

export const mutations = {
    setCurrentUser(state, newValue) {
        state.user = newValue
    }
}

export const actions = {
    initialize({commit}, token) {
        localStorage.setItem('token', token);

        return http.post(env.api + 'mentors/me', {
                token: token
            })
            .then(response => {
                localStorage.clear();
                localStorage.setItem('token', token);
                commit('setCurrentUser', response.data);
                return true;
            })
            .catch(error => {
                console.error(error)
                localStorage.removeItem('token');
                return false;
            });
    },

    refreshUser({commit}) {
        const token = localStorage.getItem('token')

        if (!token) {
            return this.dispatch('reset')
        }

        http.post(env.api + 'mentors/me', {
                token: token
            })
            .then(response => {
                commit('setCurrentUser', response.data)
            })
            .catch(error => {
                console.error(error)
                localStorage.removeItem('token');
                router.push('/login');
            });
    },

    logout({commit}) {
        http
            .post(env.api + 'logout')
            .then(() => {
                localStorage.removeItem('token');
                commit('setCurrentUser', null);
            })
            .catch(error => {
                console.error(error)
                // this.$message("Erro", "Erro ao realizar logout", "error");
            });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
