export function maskMoneyTable(value) {
    var money = parseFloat(value);
    money = isNaN(money) ? money = 0 : money
    money = money.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    return money;
}

export function moneyToFloat(value) {
    return value.replace('R$', '')
        .replaceAll('.', '')
        .trim();
}

export function formatMoneyDecimal(id, money = true) {
    const value = document.getElementById(id).value;
    console.log('entrou')
    // Remove todos os caracteres que não sejam dígitos
    if (value === '') return;

    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    let format = '';
    if (integerPart === '0' && decimalPart === '00') return document.getElementById(id).value = format;

    if (money) {
        // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
        format = 'R$ ' + integerPart + ',' + decimalPart;
    } else {
        // Formata o valor em decimal
        format = integerPart + ',' + decimalPart;
    }

    return document.getElementById(id).value = format;
}

export function toDecimal(value) {
    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    return integerPart + ',' + decimalPart;
}

export function maskCpfCnpj(value) {

    if (value.length <= 11) {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
}

export function convertDateText(dateTime) {
    let date = '';
    if (dateTime.indexOf('T') > -1) {
        date = dateTime.split('T');
    } else {
        date = dateTime.split(' ');
    }
    const months = [
        'Jan', 'Fev', 'Mar', 'Abr',
        'Mai', 'Jun', 'Jul', 'Ago',
        'Set', 'Out', 'Nov', 'Dez',
    ];

    date = date[0].split('-');
    const month = months[parseInt(date[1]) - 1];

    return `${date[2]} ${month}, ${date[0]}`;
}

export function convertHour(dateTime) {
    let date = dateTime.split(' ');
    date = date[1].split('.');

    return date[0];
}

// export function convertDateText(dateTime) {
//     let date = new Date(dateTime);
//     const months = [
//         'Janeiro', 'Fevereiro', 'Março', 'Abril',
//         'Maio', 'Junho', 'Julho', 'Agosto',
//         'Setembro', 'Outubro', 'Novembro', 'Dezembro',
//     ];
//
//     const month = months[date.getMonth()];
//
//     return `${addZeros(date.getDate())} de ${month} às ${addZeros(date.getUTCHours())}:${addZeros(date.getMinutes())}`;
// }

export function addZeros(string) {
    return String(string).padStart(2, '0');
}

export function getDate(dateTime) {
    if (dateTime.indexOf('T') > 0) {
        dateTime = dateTime.replace('T', " ");
    }
    // dateTime = dateTime.replace('T', " ");
    const date = new Date(dateTime);
    const today = new Date();

    if (isSameDay(date, today)) {
        return 'Hoje';
    } else if (isSameOneDay(date, today)) {
        return 'Ontem';
    } else if ((today - date) / (1000 * 60 * 60 * 24) < 7) {
        const daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        return daysOfWeek[date.getDay()];
    } else {
        let arr = dateTime.split(' ');
        return arr[0];
    }
}

export function getDateNotifications(dateTime) {
    if (dateTime.indexOf('T') > 0) {
        dateTime = dateTime.replace('T', " ");
    }

    const date = new Date(dateTime);
    const today = new Date();

    if (isSameDay(date, today)) {
        return `Hoje ${addZeros(date.getHours())}:${addZeros(date.getMinutes())}`;
    } else if (isSameOneDay(date, today)) {
        return `Ontem ${addZeros(date.getHours())}:${addZeros(date.getMinutes())}`;
    } else if ((today - date) / (1000 * 60 * 60 * 24) < 7) {
        const daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        return `${daysOfWeek[date.getDay()]} ${addZeros(date.getHours())}:${addZeros(date.getMinutes())}`;
    } else {
        let arr = dateTime.split(' ');
        return `${arr[0]} ${addZeros(date.getHours())}:${addZeros(date.getMinutes())}`;
    }
}

export function isSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}

export function isSameOneDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() - 1;
}

export function formatHour(dateTime) {
    dateTime = dateTime.replace('T', " ");
    const date = new Date(dateTime);

    return `${addZeros(date.getHours())}:${addZeros(date.getMinutes())}`;
}
