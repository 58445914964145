// plugins/NotifyPlugin.js
export default {
    // eslint-disable-next-line
    install(Vue, options) {
        Vue.prototype.$notifySuccess = function (error) {
            let message = 'Cadastro realizado com sucesso!';
            if (typeof error === 'string') {
                message = error;
            } else if (error.response && typeof error.response.data === 'object') {
                message = '';
                Object.keys(error.response.data).forEach(element => {
                    message += `${error.response.data[element]} <br>`;
                });
            } else if (error.response && typeof error.response.data === 'string') {
                message = error.response.data;
            }
            this.$notify({
                title: `<i class="fa fa-check-circle mr-2 mt-2"></i> <span>${message}</span>`,
                type: 'success',
                duration: 3000,
                closeOnClick: true
            });
        }
    }
}
