<template>
    <div>
        <router-view></router-view>
        <notifications position="top right"/>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>
