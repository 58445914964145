export const state = {
    sections: [],
    newSections: [],
}

export const mutations = {
    setSections(state, newValue) {
        state.sections = newValue;
    },

    setNewSections(state, newValue) {
        state.newSections.push(newValue);
    },

    resetValues(state) {
        state.newSections = [];
        state.sections = [];
    },

    updateNewSections(state, {sectionIndex, contentIndex, newValue}) {
        const data = state.newSections;

        if (data[sectionIndex].contents[contentIndex]) {
            data[sectionIndex].contents[contentIndex] = newValue;
        } else {
            data[sectionIndex].contents.push(newValue);
        }

        state.newSections = data;
    },

    addContentNewSections(state, {sectionIndex, payload}) {
        state.newSections[sectionIndex].contents.push(payload);
    }
}

export const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
