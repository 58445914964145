export function startLoading(id, button) {
    if (document.getElementById(id)) document.getElementById(id).style.opacity = '.2';
    if (document.getElementById(button)) document.getElementById(button).setAttribute('disabled', true);
}

export function endLoading(id, button) {
    setTimeout(function () {
        if (document.getElementById(id)) document.getElementById(id).style.opacity = '1';
        if (document.getElementById(button)) document.getElementById(button).removeAttribute('disabled');
    }, 300)
}

export function loadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '.2'
    // loadCard();
}

export function loadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '.2'
    }
    const placeholderElement = document.querySelector('.simplebar-placeholder');
    const width = parseInt(placeholderElement.offsetWidth) / 2 + 30;

    document.getElementById('spinner').style.display = 'block'
    document.getElementById('spinner').style.right = `calc(50% - ${width}px)`
}

export function endLoadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '1'

    endLoadCard();
}

export function endLoadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '1'
    }
    const spinner = document.getElementById('spinner');
    if (spinner) {
        spinner.style.display = 'none';
        spinner.style.right = `calc(50% - 2.5rem)`;
    }
}
