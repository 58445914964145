import Vue from 'vue'
import VueRouter from 'vue-router'
import env from '@/env'

Vue.use(VueRouter)

import {routes} from './routes'
// import {createStore} from '../store'
import store from '../store'
import scrollBehavior from './scrollBehavior'

export const createRouter = () => {
    // const store = createStore()

    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        linkActiveClass: 'active',
        linkExactActiveClass: 'active',
        routes,
        scrollBehavior
    })

    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.auth)) {
            if (!store.getters['auth/isLoggedIn']) {
                next({ name: 'login' });
            } else {
                const currentUser = store.state.auth.user;
                if (!currentUser.plan) {
                    const token = localStorage.getItem('token');
                    next({ name: 'checkout', query: { token: token } });
                }
                document.title = to.meta.title + ' | ' + env.title;
                store.commit('api/resetValues', {});
                next();
            }
        } else {
            document.title = to.meta.title + ' | ' + env.title;
            next();
        }
    })

    return router
}

const router = createRouter()
export default router
